@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Roboto Slab";
  src: local("Roboto Slab"), url(./fonts/RobotoSlab-Regular.ttf);
}

@font-face {
  font-family: "Roboto Slab";
  font-weight: 600;
  src: local("Roboto Slab"), url(./fonts/RobotoSlab-SemiBold.ttf);
}

@font-face {
  font-family: "Roboto Slab";
  font-weight: 700;
  src: local("Roboto Slab"), url(./fonts/RobotoSlab-Bold.ttf);
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: local("Roboto"), url(./fonts/Roboto-Medium.ttf);
}
